jQuery( document ).ready( function( $ ){

    function reposition_backgrounds(){
        let page_pos    = $( window ).scrollTop();
        let win_height  = $( window ).height();
        // $( '.section-background-fixed' ).each( function(){
        //     let $section    = $( this ).closest( 'section' );
        //     let top         = $section.position().top;
        //     if( page_pos + win_height >= top ){
        //         let offset = page_pos + win_height - top;
        //         let height = $section.outerHeight();
        //         if( height - offset > 0 ){
        //             let bottom = height - offset;
        //             $( this ).css({
        //                 'bottom': bottom + 'px',
        //                 'top': 'initial'
        //             })
        //         }
        //         else{
        //             let top = -1 * (height - offset);
        //             $( this ).css({
        //                 'top': top + 'px',
        //                 'bottom': 'initial'
        //             })
        //         }
        //     }
        // });
        $( '.section-background-fixed' ).each( function(){
            let $section    = $( this ).closest( 'section' );
            let top         = $section.position().top;
            if( page_pos + win_height >= top ){
                $(this).css({ display: 'block' });
            }
            else{
                $(this).css({display: 'none' });
            }
        });
    }

    $( document ).on( 'scroll', reposition_backgrounds );
    reposition_backgrounds();

});