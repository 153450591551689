jQuery( document ).ready( function( $ ){

    var banner_interval;
    if( $( '.banner' ).length ){
        rotate_banner();
        banner_interval = setInterval( rotate_banner, 7000 );
    }

    function rotate_banner(){
        let $active = $( '.banner-images img.active' );
        let $next   = $active.next().length ? $active.next() : $( '.banner-images img' ).first();
        $active.animate( {opacity: 0}, 1000, 'linear', () => {
            $next.animate( {opacity: 1}, 1000, 'linear' );
            $active.removeClass( 'active' );
            $next.addClass( 'active' );
        });
    }

});