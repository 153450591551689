jQuery( document ).ready( function( $ ){

    $( '.slider' ).slick({
        infinite: true,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

});