( function( $ ){

    $( '.search-trigger' ).on( 'click', (e) => {
        let is_visible = $( '.search-popup' ).is( ':visible' );
        if( ! is_visible ){
            $( '.search-popup' ).fadeIn();
            $( '.search-input' ).focus();
            e.stopPropagation();
        }
    });

    $( document ).on( 'click', (e) => {
        if( 
            $( e.target ).closest( '.search-popup' ).length == 0
            && $( '.search-popup' ).is( ':visible' )
        ){
            $( '.search-popup' ).fadeOut();
        }
    });

})( jQuery );