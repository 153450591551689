( function( $ ){

    let menu_trap           = focusTrap.createFocusTrap( '.header-menu' );
    let mobile_menu_open    = false;

    $( '.mobile-menu-open' ).on( 'click', () => {
        $( '.header-menu' ).addClass( 'open' );
        menu_trap.activate();
        mobile_menu_open = true;
    });

    $( '.mobile-menu-close' ).on( 'click', () => {
        $( '.header-menu' ).removeClass( 'open' );
        menu_trap.deactivate();
        mobile_menu_open = false;
    });

    const scrollToSection = ( section_class ) => {
        let $section        = $( 'section.' + section_class ).first();
        if( $section.length ){
            $( 'html, body' ).animate({
                scrollTop: $section.offset().top
            }, 500 );
            if( mobile_menu_open ){
                $( '.header-menu' ).removeClass( 'open' );
                menu_trap.deactivate();
                mobile_menu_open = false;
            }
            return true;
        }
        return false;
    }

    const getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    $( '#menu-header-menu a' ).on( 'click', function( e ){
        let section_class   = $( this ).attr( 'href' ).replace( '#', '' );
        if( ! scrollToSection( section_class ) ){
            if( $(this).closest( 'li' ).hasClass( 'home' ) ){
                window.location.href = ThemeJS.Variables.Home + '?sec=' + section_class;
            }
        }
        else{
            e.preventDefault();
        }
    });

    if( getUrlParameter( 'sec' ) ){
        scrollToSection( getUrlParameter( 'sec' ) );
    }

    function fixHeaderHeight(){
        if( $( window ).height() < 600 && $( window ).width() > 991 ){
            $( '.header' ).addClass( 'scrollable' );
        }
        else{
            $( '.header' ).removeClass( 'scrollable' );
        }
    }

    fixHeaderHeight();
    $( window ).resize( fixHeaderHeight );

})( jQuery );
