(function( $, root ){

    $.fn.toggleAccordion = function () {
        if ( !this.hasClass('accordion-single') ) { return; }
        if ( this.hasClass('accordion_open') ) {
            this.removeClass('accordion_open');
            $('.accordion-content', this).slideUp();
            $('.accordion-content', this).attr('aria-expanded', false);
        } else {
            $('.accordion-content', '.accordion_open').attr('aria-expanded', false);
            $('.accordion-content', '.accordion_open').slideUp();
            $('.accordion_open').removeClass( 'accordion_open' );
            this.addClass('accordion_open');
            $('.accordion-content', this).slideDown();
            $('.accordion-content', this).attr('aria-expanded', true);
        }
    }

    $(document).on('click', '.accordion-single button', function () {
        $(this).closest('.accordion-single').toggleAccordion();
    });

    $(function () {
        $('.accordion_open .accordion-content').show();
    });

})(jQuery, this);